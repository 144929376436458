import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import COLOURS from '../../util/COLOURS';

import { resetAllFilters } from '../../actions/filters';

import { recordSearchTabResetButtonClick } from '../../actions/ui';

const Logo = ({ resetFilters }) => (
    <Link to="/" href="/" onClick={resetFilters} className="header__home">
        <span className="visually-hidden">OS Hub</span>
        <div className="header__logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 972.96 364.15">
                <defs>
                    <style>{`.cls-1{ fill: ${COLOURS.NEAR_BLACK} }`}</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            className="cls-1"
                            d="M360,2.38H231.89L180,92.27,128.11,2.38H0V130.49l89.89,51.89L0,234.27V362.38H128.11L180,272.5l51.89,89.88H360V234.27l-89.88-51.89L360,130.49Zm-270.58,344L16,273,116.28,246.1ZM16,91.8l73.4-73.4,26.86,100.26ZM180,242.39a60,60,0,1,1,60-60A60,60,0,0,1,180,242.39ZM344,273l-73.39,73.39L243.72,246.1ZM243.72,118.66,270.59,18.4,344,91.8Z"
                        />
                        <path
                            className="cls-1"
                            d="M470.76,115.36q-27.7,0-42.35-15.33T413.77,58q0-17.61,6.33-30.57a46,46,0,0,1,19-20.19Q451.76,0,470.76,0q27.71,0,42.15,15.73T527.35,58q0,17.21-6.43,30.37a44.81,44.81,0,0,1-19,20.09Q489.35,115.35,470.76,115.36Zm-.4-26.71q10.09,0,16.72-3.86A23.81,23.81,0,0,0,497,73.71,37.86,37.86,0,0,0,500.24,58,39,39,0,0,0,497,42a26.68,26.68,0,0,0-9.9-11.67q-6.63-4.35-16.72-4.36-14.44,0-22.06,9.1t-7.62,23A37.19,37.19,0,0,0,444,73.51a24.5,24.5,0,0,0,9.79,11.18Q460.27,88.65,470.36,88.65Z"
                        />
                        <path
                            className="cls-1"
                            d="M562,113.38H534.27V2h47.1q19,0,30,9.79t11,29.39q0,16.62-11.28,26.22T581.37,77H562Zm15.23-89.24H562V57.58h15.23a23.8,23.8,0,0,0,14.25-4.25q5.94-4.26,5.94-12.57,0-8.5-5.84-12.56A24.57,24.57,0,0,0,577.21,24.14Z"
                        />
                        <path
                            className="cls-1"
                            d="M655.77,91.42h59.36v22H628.66V2H713V25.53H655.77V45.91h42.54V68.07H655.77Z"
                        />
                        <path
                            className="cls-1"
                            d="M796.06,2H825v111.4h-53L751.14,14.05h-.39v99.33h-28.7V2h49.08l24.53,99.33h.4Z"
                        />
                        <path
                            className="cls-1"
                            d="M470,239.75a79.65,79.65,0,0,1-18.6-2.17,50.74,50.74,0,0,1-16.43-7A37.09,37.09,0,0,1,423.17,218q-4.46-7.73-4.45-18.6h27.7a12.37,12.37,0,0,0,.89,3.66,15.27,15.27,0,0,0,3.56,5.24,20.52,20.52,0,0,0,7.72,4.66q5,1.88,13.55,1.88a51.48,51.48,0,0,0,7.72-.6,15.91,15.91,0,0,0,6.73-2.48,6.56,6.56,0,0,0,2.77-5.83,7.33,7.33,0,0,0-3.86-6.53,34.53,34.53,0,0,0-10.09-3.86q-6.24-1.49-13.66-3.07a144.68,144.68,0,0,1-14.74-4,52.86,52.86,0,0,1-13.35-6.43,27.21,27.21,0,0,1-9.3-10.48q-3.27-6.43-2.68-16.13,1-14.64,13.86-22.86t35.81-8.21a61,61,0,0,1,23,4.36A41.14,41.14,0,0,1,512,142q6.84,8.91,6.83,22.56H492.13q0-6.13-4.06-9.2a22.28,22.28,0,0,0-9.69-4.06,60.53,60.53,0,0,0-10.19-1A35.53,35.53,0,0,0,456,152.1a6.77,6.77,0,0,0-4.85,6.92,7.84,7.84,0,0,0,4,7A34,34,0,0,0,465.42,170q6.33,1.49,13.95,3t15.13,3.66a51.57,51.57,0,0,1,13.56,6,27.68,27.68,0,0,1,9.5,10.19q3.45,6.33,2.67,16.23-1,14.43-14.55,22.55T470,239.75Z"
                        />
                        <path
                            className="cls-1"
                            d="M577.41,239.75q-24.93,0-37.69-12.76T527,190.29V126.37h29.68v63.72q0,8.31,2.17,13.16a17.58,17.58,0,0,0,5.44,7.22,13.39,13.39,0,0,0,6.83,2.67c2.38.2,4.48.3,6.33.3a46.89,46.89,0,0,0,9.4-1q4.85-1,8.21-6.14t3.37-16.22V126.37h29.68v63.92q0,24.33-12.57,36.9T577.41,239.75Z"
                        />
                        <path
                            className="cls-1"
                            d="M663.69,237.78H636V126.37h47.1q19,0,30,9.8t11,29.38q0,16.62-11.28,26.22t-29.68,9.6H663.69Zm15.23-89.25H663.69V182h15.23a23.8,23.8,0,0,0,14.25-4.25q5.94-4.26,5.94-12.56,0-8.5-5.84-12.57A24.57,24.57,0,0,0,678.92,148.53Z"
                        />
                        <path
                            className="cls-1"
                            d="M756.69,237.78H729V126.37h47.1q19,0,30,9.8t11,29.38q0,16.62-11.28,26.22t-29.68,9.6H756.69Zm15.23-89.25H756.69V182h15.23a23.8,23.8,0,0,0,14.25-4.25q5.94-4.26,5.94-12.56,0-8.5-5.84-12.57A24.57,24.57,0,0,0,771.92,148.53Z"
                        />
                        <path
                            className="cls-1"
                            d="M822.78,237.78V126.37h27.7v87.86h48.08v23.55Z"
                        />
                        <path
                            className="cls-1"
                            d="M906.48,237.78V199.39h-.2l-37.8-73h31.07L920.72,176l21.17-49.67H973l-39,73v38.39Z"
                        />
                        <path
                            className="cls-1"
                            d="M448.6,362.17H420.89V250.77H448.6v44.91h42.54V250.77h27.7v111.4h-27.7V316.66H448.6Z"
                        />
                        <path
                            className="cls-1"
                            d="M577.21,364.15q-24.93,0-37.69-12.77t-12.77-36.7V250.77h29.69v63.71q0,8.31,2.17,13.16a17.58,17.58,0,0,0,5.44,7.22,13.4,13.4,0,0,0,6.83,2.68q3.55.29,6.33.29a46.17,46.17,0,0,0,9.4-1q4.85-1,8.21-6.13t3.37-16.23V250.77h29.68v63.91q0,24.33-12.57,36.9T577.21,364.15Z"
                        />
                        <path
                            className="cls-1"
                            d="M635.58,362.17V250.77h57.78a47.26,47.26,0,0,1,11.88,1.58,44.16,44.16,0,0,1,11.47,4.75,24.93,24.93,0,0,1,8.71,8.7q3.36,5.55,3.36,14.25a26.68,26.68,0,0,1-2.57,11.68q-2.57,5.34-9.2,9.1t-19.29,3.76v1q14.44.39,22.36,3.95t11.08,9.21a26,26,0,0,1,3.17,13q0,9.1-3.57,14.94a24.59,24.59,0,0,1-9.4,9,53,53,0,0,1-12.66,4.85,56.19,56.19,0,0,1-13.46,1.68H635.58Zm27.11-67.48h17.42a82.14,82.14,0,0,0,9.89-.59,18.55,18.55,0,0,0,8.31-2.87c2.24-1.51,3.36-4.12,3.36-7.81s-1-6.07-3.06-7.52a17,17,0,0,0-8-2.73,96.71,96.71,0,0,0-10.48-.54H662.69Zm.2,45.81H681.1a105.52,105.52,0,0,0,12-.64,18.5,18.5,0,0,0,9.2-3.32q3.56-2.67,3.56-9t-3.46-8.9a16.88,16.88,0,0,0-9.1-3q-5.64-.39-12.17-.4H662.89Z"
                        />
                    </g>
                </g>
            </svg>
        </div>
    </Link>
);

function mapDispatchToProps(dispatch) {
    // We don't load the logo in embed mode.
    const embedded = false;
    return {
        resetFilters: () => {
            dispatch(recordSearchTabResetButtonClick());
            return dispatch(resetAllFilters(embedded));
        },
    };
}

export default connect(null, mapDispatchToProps)(Logo);
