import React from 'react';
import { string } from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FacilitiesIcon({ color }) {
    return (
        <SvgIcon viewBox="0 0 18 16" style={{ transform: 'scale(0.6)' }}>
            <path
                fill={color}
                d="M1.5 15.5002C1.08333 15.5002 0.729333 15.3542 0.438 15.0622C0.146 14.7709 0 14.4169 0 14.0002C0 13.5836 0.146 13.2296 0.438 12.9382C0.729333 12.6462 1.08333 12.5002 1.5 12.5002C1.91667 12.5002 2.27067 12.6462 2.562 12.9382C2.854 13.2296 3 13.5836 3 14.0002C3 14.4169 2.854 14.7709 2.562 15.0622C2.27067 15.3542 1.91667 15.5002 1.5 15.5002ZM5 15.0002V13.0002H18V15.0002H5ZM1.5 9.50024C1.08333 9.50024 0.729333 9.35424 0.438 9.06224C0.146 8.77091 0 8.41691 0 8.00024C0 7.58358 0.146 7.22958 0.438 6.93824C0.729333 6.64624 1.08333 6.50024 1.5 6.50024C1.91667 6.50024 2.27067 6.64624 2.562 6.93824C2.854 7.22958 3 7.58358 3 8.00024C3 8.41691 2.854 8.77091 2.562 9.06224C2.27067 9.35424 1.91667 9.50024 1.5 9.50024ZM5 9.00024V7.00024H18V9.00024H5ZM1.5 3.50024C1.08333 3.50024 0.729333 3.35424 0.438 3.06224C0.146 2.77091 0 2.41691 0 2.00024C0 1.58358 0.146 1.22958 0.438 0.938244C0.729333 0.646244 1.08333 0.500244 1.5 0.500244C1.91667 0.500244 2.27067 0.646244 2.562 0.938244C2.854 1.22958 3 1.58358 3 2.00024C3 2.41691 2.854 2.77091 2.562 3.06224C2.27067 3.35424 1.91667 3.50024 1.5 3.50024ZM5 3.00024V1.00024H18V3.00024H5Z"
            />
        </SvgIcon>
    );
}

FacilitiesIcon.defaultProps = {
    color: '#1C1B1F',
};

FacilitiesIcon.propTypes = {
    color: string,
};
