import React from 'react';

export default function FooterShapesMobile() {
    return (
        <div className="footer__shapes-mobile">
            <svg
                viewBox="0 0 328 164"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M82.456 164h82V82l-82 82ZM82.456 123h41V82l-41 41Z"
                    fill="#FFA6D0"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="m0 164 41-82v82H0Zm41 0h41V82l-41 82Z"
                    fill="#FFCF3F"
                />
                <path
                    d="M205 164c22.646 0 41-18.354 41-41s-18.354-41-41-41-41 18.354-41 41 18.354 41 41 41Z"
                    fill="#62CC74"
                />
                <path
                    d="M246 122.317h40.317V82M286.317 122.317l-28.509-28.509M286.317 122.317l-34.914-20.159M286.317 122.317l-10.432-38.94M286.317 122.317l-20.159-34.914M286.316 122.317l-38.945-10.432M246 163.317h40.317V123M286.317 163.317l-28.509-28.509M286.317 163.317l-34.914-20.159M286.317 163.317l-10.432-38.941M286.317 163.317l-20.159-34.914M286.316 163.317l-38.945-10.432M287 122.317h40.317V82M327.317 122.317l-28.509-28.509M327.317 122.317l-34.914-20.159M327.317 122.317l-10.432-38.94M327.317 122.317l-20.159-34.914M327.316 122.317l-38.945-10.432M287 163.317h40.317V123M327.317 163.317l-28.509-28.509M327.317 163.317l-34.914-20.159M327.317 163.317l-10.432-38.941M327.317 163.317l-20.159-34.914M327.316 163.317l-38.945-10.432"
                    stroke="#F9F7F7"
                    strokeWidth="1.283"
                    strokeMiterlimit="10"
                />
                <path d="m287 0 41 82h-82l41-82Z" fill="#FF2727" />
            </svg>
        </div>
    );
}
