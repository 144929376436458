import React from 'react';
import { string } from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MapIcon({ color }) {
    return (
        <SvgIcon viewBox="0 0 16 20" style={{ transform: 'scale(0.6)' }}>
            <path
                fill={color}
                d="M8 10.0002C8.55 10.0002 9.021 9.80424 9.413 9.41224C9.80433 9.02091 10 8.55024 10 8.00024C10 7.45024 9.80433 6.97924 9.413 6.58724C9.021 6.19591 8.55 6.00024 8 6.00024C7.45 6.00024 6.97933 6.19591 6.588 6.58724C6.196 6.97924 6 7.45024 6 8.00024C6 8.55024 6.196 9.02091 6.588 9.41224C6.97933 9.80424 7.45 10.0002 8 10.0002ZM8 17.3502C10.0333 15.4836 11.5417 13.7876 12.525 12.2622C13.5083 10.7376 14 9.38358 14 8.20024C14 6.38358 13.4207 4.89591 12.262 3.73724C11.104 2.57924 9.68333 2.00024 8 2.00024C6.31667 2.00024 4.89567 2.57924 3.737 3.73724C2.579 4.89591 2 6.38358 2 8.20024C2 9.38358 2.49167 10.7376 3.475 12.2622C4.45833 13.7876 5.96667 15.4836 8 17.3502ZM8 20.0002C5.31667 17.7169 3.31267 15.5959 1.988 13.6372C0.662667 11.6792 0 9.86691 0 8.20024C0 5.70024 0.804333 3.70858 2.413 2.22524C4.021 0.741911 5.88333 0.000244141 8 0.000244141C10.1167 0.000244141 11.979 0.741911 13.587 2.22524C15.1957 3.70858 16 5.70024 16 8.20024C16 9.86691 15.3377 11.6792 14.013 13.6372C12.6877 15.5959 10.6833 17.7169 8 20.0002Z"
            />
        </SvgIcon>
    );
}

MapIcon.defaultProps = {
    color: '#1C1B1F',
};

MapIcon.propTypes = {
    color: string,
};
