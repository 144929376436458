import React from 'react';

export default ({ color = '#191919' }) => (
    <svg
        width="23"
        height="16"
        viewBox="0 0 23 16"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.5 16V13.2C0.5 12.6333 0.646 12.1123 0.938 11.637C1.22933 11.1623 1.61667 10.8 2.1 10.55C3.13333 10.0333 4.18333 9.64567 5.25 9.387C6.31667 9.129 7.4 9 8.5 9C9.6 9 10.6833 9.129 11.75 9.387C12.8167 9.64567 13.8667 10.0333 14.9 10.55C15.3833 10.8 15.7707 11.1623 16.062 11.637C16.354 12.1123 16.5 12.6333 16.5 13.2V16H0.5ZM18.5 16V13C18.5 12.2667 18.296 11.5623 17.888 10.887C17.4793 10.2123 16.9 9.63333 16.15 9.15C17 9.25 17.8 9.42067 18.55 9.662C19.3 9.904 20 10.2 20.65 10.55C21.25 10.8833 21.7083 11.254 22.025 11.662C22.3417 12.0707 22.5 12.5167 22.5 13V16H18.5ZM8.5 8C7.4 8 6.45833 7.60833 5.675 6.825C4.89167 6.04167 4.5 5.1 4.5 4C4.5 2.9 4.89167 1.95833 5.675 1.175C6.45833 0.391667 7.4 0 8.5 0C9.6 0 10.5417 0.391667 11.325 1.175C12.1083 1.95833 12.5 2.9 12.5 4C12.5 5.1 12.1083 6.04167 11.325 6.825C10.5417 7.60833 9.6 8 8.5 8ZM18.5 4C18.5 5.1 18.1083 6.04167 17.325 6.825C16.5417 7.60833 15.6 8 14.5 8C14.3167 8 14.0833 7.97933 13.8 7.938C13.5167 7.896 13.2833 7.85 13.1 7.8C13.55 7.26667 13.8957 6.675 14.137 6.025C14.379 5.375 14.5 4.7 14.5 4C14.5 3.3 14.379 2.625 14.137 1.975C13.8957 1.325 13.55 0.733333 13.1 0.2C13.3333 0.116666 13.5667 0.0623335 13.8 0.0370002C14.0333 0.0123335 14.2667 0 14.5 0C15.6 0 16.5417 0.391667 17.325 1.175C18.1083 1.95833 18.5 2.9 18.5 4ZM2.5 14H14.5V13.2C14.5 13.0167 14.4543 12.85 14.363 12.7C14.271 12.55 14.15 12.4333 14 12.35C13.1 11.9 12.1917 11.5623 11.275 11.337C10.3583 11.1123 9.43333 11 8.5 11C7.56667 11 6.64167 11.1123 5.725 11.337C4.80833 11.5623 3.9 11.9 3 12.35C2.85 12.4333 2.72933 12.55 2.638 12.7C2.546 12.85 2.5 13.0167 2.5 13.2V14ZM8.5 6C9.05 6 9.521 5.804 9.913 5.412C10.3043 5.02067 10.5 4.55 10.5 4C10.5 3.45 10.3043 2.97933 9.913 2.588C9.521 2.196 9.05 2 8.5 2C7.95 2 7.47933 2.196 7.088 2.588C6.696 2.97933 6.5 3.45 6.5 4C6.5 4.55 6.696 5.02067 7.088 5.412C7.47933 5.804 7.95 6 8.5 6Z"
            fill="#1C1B1F"
        />
    </svg>
);
